const API_ENDPOINTS = {



 API:'https://hotelbe.quarknetworks.net'


    
}


export default API_ENDPOINTS;

























































































































































































































































































































































































































































































































































































































































































